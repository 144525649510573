import React from 'react';
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import {DRACOLoader} from "three/addons/loaders/DRACOLoader";
import {Clone, useGLTF} from "@react-three/drei";

export default function Model ({modelRef}) {

    // draco is used by default and fetched from CDN - no need to attach decoder files to the public folder
    const model = useGLTF('./hamburger.glb');

    //this code can be replaced with drei hook "useGLTF"
    // const model = useLoader( // this loader by default is blocking the rendering of rest of the app
    //     GLTFLoader,
    //     './hamburger.glb',
    //     // './Flighthelmet/glTF/FlightHelmet.gltf',
    //     (loader) => {
    //         const dracoLoader = new DRACOLoader();
    //         dracoLoader.setDecoderPath('./draco/');
    //         loader.setDRACOLoader(dracoLoader);
    //     }
    // );

    return (
        <>
            <Clone
                // ref={modelRef}
                object={model.scene}
                position={[0,1,0]}
                scale={0.2}
            />
            <Clone
                // ref={modelRef}
                object={model.scene}
                position={[2,1,0]}
                scale={0.2}
            />
            <Clone
                // ref={modelRef}
                object={model.scene}
                position={[0,1,2]}
                scale={0.2}
            />
        </>
    );
};

useGLTF.preload('./hamburger.glb');
