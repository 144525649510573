import './style.css'
import ReactDOM from 'react-dom/client'
import {Canvas, useFrame, useThree} from '@react-three/fiber'
import Experience from './Experience.js'
import AppContext, {AppProvider} from "./AppContext";
import {useContext} from "react";
import AppUI from "./AppUI";
import {Vector3} from "three";

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
    <AppProvider>
        <Canvas
            shadows
            camera={ {
                fov: 45,
                near: 0.1,
                far: 200,
                position: [ -10, 6, 10 ],
                rotation: [0, 0, 0],
            } }
        >
            <Experience />
        </Canvas>
        <AppUI />
    </AppProvider>
)
