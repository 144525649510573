import React, { useRef, useContext, useEffect } from "react";
import {useGLTF, useAnimations, Html} from "@react-three/drei";
import {extend, useFrame, useThree} from "@react-three/fiber";
import AppContext from "./AppContext";
import * as THREE from 'three';

export default function Astronaut(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF('./My/astronaut2.glb');
    const { actions, mixer } = useAnimations(animations, group);

    const modelRef = useRef();
    const htmlRef = useRef();
    const skinnedMeshRef = useRef();
    const {animation: a} = useContext(AppContext);

    const newMaterial = new THREE.MeshStandardMaterial({
        color: "#7da909",
        flatShading:true,
        roughness: 0.8
    });

    const prevAniSeq = useRef(a);
    const currentAction = useRef(null);

    useEffect(() => {
        const action = actions[a];
        if (action) {
            // Check if previous animation is running
            const prevAction = actions[prevAniSeq.current];
            console.log(prevAction);
            if (prevAction && prevAction.isRunning()) {
                prevAction.setLoop(THREE.LoopOnce, 1);
                prevAction.clampWhenFinished = true;
                currentAction.current = action; // Store the current action to be played after previous animation is finished
            } else {
                // If previous animation is not running, play the current animation
                action.reset().fadeIn(0.5).play();
                prevAniSeq.current = a;
            }
        }
        // return () => {
        //     console.log('finished useeffect 1')
        // }
    }, [a]);

    useEffect(() => {
        mixer.addEventListener('finished', (e) => {
            console.log('finished')
            console.log(currentAction.current._clip.name);
            actions[prevAniSeq.current].fadeOut(0.5).play();
            currentAction.current.reset().fadeIn(0.5).play();
            currentAction.current.setLoop(THREE.LoopRepeat);
            currentAction.current.clampWhenFinished = false;
            prevAniSeq.current = currentAction.current._clip.name;
        })
        return () => {
            //@todo cleanup event listener
        }
    }, [])

    // useFrame((state, delta) => {
    //     if(htmlRef.current){
    //         htmlRef.current.position = skinnedMeshRef.current.position;
    //         // console.log(htmlRef.current.position);
    //     }
    // })

    return (
        <group ref={group} scale={0.5} position-y={-1} {...props} dispose={null}>
            <group ref={modelRef} name="Scene">
                <group name="Starman" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <primitive ref={skinnedMeshRef} object={nodes.mixamorigHips} />
                    <skinnedMesh
                        castShadow
                        name="Char"
                        geometry={nodes.Char.geometry}
                        material={newMaterial}
                        // material={nodes.Char.material}
                        skeleton={nodes.Char.skeleton}
                    />
                    {/*<Html*/}
                    {/*    ref={htmlRef}*/}
                    {/*    position={[100,0,-800]}*/}
                    {/*    wrapperClass={'astronaut-hint'}*/}
                    {/*    as='div' // Wrapping element (default: 'div')*/}
                    {/*    center={false} // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]*/}
                    {/*    distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.*/}
                    {/*    zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])*/}
                    {/*>*/}
                    {/*    <h1>Wait a minute!</h1>*/}
                    {/*    <p>I need to finish my walk...</p>*/}
                    {/*</Html>*/}
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/astronaut2.glb");
