import React, {useRef} from 'react';
import {useFrame} from "@react-three/fiber";
import {Center} from "@react-three/drei";

const Placeholder = (props) => {

    // const placeholderRef = useRef();
    // const modelRef = useRef();
    const loaderRef = useRef();

    useFrame((state, delta) => {
        // if(modelRef.current){
        //     modelRef.current.rotation.y += delta / 8
        // }
        if (loaderRef){
            loaderRef.current.rotation.y += delta *4
        }
    })

    // useFrame((_, delta) => {
    //     placeholderRef.current.rotation.y += delta
    // })

    return (
        <group ref={loaderRef} scale={[1,2,1]} position-y={1}>
            <Center>
                <mesh position-x={2} scale={0.3} rotation={[Math.PI / 4, 0, Math.PI / 4]}>
                    <boxGeometry args={[1, 1, 1, 2, 2, 2]}/>
                    <meshStandardMaterial color={'blue'}/>
                </mesh>
            </Center>
        </group>
    );
};

export default Placeholder;
