import React, {createContext, useState} from 'react';

const AppContext = createContext();

export const AppProvider = ({children}) => {

    const [animation, setAnimation] = useState('Call');
    const [camPos, setCamPos] = useState();

    const value={
        animation,
        setAnimation,
        camPos,
        setCamPos
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
