import React, {useContext, useEffect, useRef, useState} from 'react';
import {useGLTF, useAnimations} from "@react-three/drei";
import {useControls, button} from "leva";
import AppContext from "./AppContext";

export default function Fox() {

    // const model = useGLTF('./Fox/glTF/Fox.gltf');
    const model = useGLTF('./My/astronaut2.glb');

    const animations = useAnimations(model.animations, model.scene);
    console.log(animations);

    // const {animationName} = useControls({
    //     animationName: { options: animations.names }
    // })
    //
    // useEffect(() => {
    //     const action = animations.actions[animationName];
    //     action
    //         .reset()
    //         .fadeIn(0.5)
    //         .play();
    //
    //     // setTimeout(() => {
    //     //     animations.actions.Run.play();
    //     //     animations.actions.Run.crossFadeFrom(animations.actions.Walk, 1)
    //     // }, 2500)
    //     return () => {
    //         action.fadeOut(0.5).play();
    //     }
    // }, [animationName]);
    //
    // useEffect(() => {
    //     animations.actions.Call.play();
    //
    //     setTimeout(() => {
    //         animations.actions.Walk.play();
    //         animations.actions.Walk.crossFadeFrom(animations.actions.Call, 1)
    //     }, 2500)
    //
    // }, []);

    return (
        <>
            <primitive rotation-y={Math.PI * 2} scale={0.5} position-y={-1} object={model.scene} />
        </>
    );
};

useGLTF.preload('./Fox/glTF/Fox.gltf');
