import React, {useContext} from 'react';
import appContext from "./AppContext";

const AppUI = () => {

    const {setAnimation} = useContext(appContext);
    const {setCamPos} = useContext(appContext);

    return (
        <div className={'ui'}>
            <button onClick={() => {
                setAnimation('Breakdance')
                setCamPos('camPos3')
            }}>Breakdance</button>
            <button onClick={() => {
                setAnimation('Call');
                setCamPos('camPos1');
            }}>Call</button>
            <button onClick={() => {
                setAnimation('Dance');
                setCamPos('camPos2');
            }}>Dance</button>
            <button onClick={() => setAnimation('Typing')}>Typing</button>
            <button onClick={() => setAnimation('Walk')}>Walk</button>
        </div>
    );
};

export default AppUI;
