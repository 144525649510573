import {Center, ContactShadows, OrbitControls, Sky, useTexture, Cloud} from '@react-three/drei'
import { Perf } from 'r3f-perf'
import {useRef, Suspense, useEffect, useState, useMemo, useContext} from "react";
import Model from "./Model";
import {useFrame, useThree} from "@react-three/fiber";
import Placeholder from "./Placeholder";
import Hamburger from "./Hamburger";
import Fox from "./Fox";
import Astronaut from "./Astronaut";
import * as THREE from "three";
import { useSpring, a, config } from "@react-spring/three";
import AppContext from "./AppContext";

const t = new THREE.Vector3();

const camPosition = {
    camPosDef: {
        position: [2,5,6],
        target: [0,0,0]
    },
    camPos1: {
        position: [3,8,7],
        target: [0,0,0]
    },
    camPos2: {
        position: [8,7,2],
        target: [0,1,0]
    },
    camPos3: {
        position: [1,12,1],
        target: [0,0,0]
    }
}

const CameraAnimation = ({ camPos = 'camPosDef' }) => {
    const [camSettings, setCamSettings] = useState(camPosition[camPos]);
    useEffect(() => {
        setCamSettings(camPosition[camPos])
    }, [camPos]);

    return (
        <AnimateCameraToTarget
            position={camSettings.position}
            target={camSettings.target}
        />
    );
};

const CameraWrapper = ({ cameraPosition, target }) => {
    const { camera } = useThree();
    camera.position.set(...cameraPosition);
    camera.lookAt(t.set(...target));
    return null;
};
const ControlsWrapper = ({ target }) => {
    const { controls } = useThree();
    if (controls) {
        controls.target.set(...target);
    }
    return null;
};

const AnimateCameraToTarget = ({position, target}) => {

    const { camera, controls } = useThree();

    const AnimateCamera = useMemo(() => a(CameraWrapper), []);
    const AnimatedControls = useMemo(() => a(ControlsWrapper), []);

    const s = useSpring({
        from: {
            position: [0, 0, 0],
            target: [0, 0, 0]
        },
        // onStart: () => {
        //     if (!controls) return;
        //     controls.enabled = false;
        // },
        // onRest: () => {
        //     if (!controls) return;
        //     controls.enabled = true;
        // },
        config: config.wobbly,
    })
    s.position.start({
        from: camera.position.toArray(),
        to: position
    });
    // s.target.start({
    //     from: controls ? controls.target.toArray() : [0,0,0],
    //     to: target
    // })
    return (
        <>
            <AnimateCamera cameraPosition={s.position} target={s.target} />
            {/*<AnimatedControls target={s.target} />*/}
        </>
    );
}

export default function Experience() {

    // const {camera} = useThree();
    // useEffect(() => {
    //     camera.lookAt(0,0,0);
    // }, []);

    const grassTex = useTexture('./My/velocity-floor-bg.jpg')

    const {camPos} = useContext(AppContext);
    // grassTex.wrapS = THREE.ClampToEdgeWrapping;
    // grassTex.wrapT = THREE.ClampToEdgeWrapping;
    // grassTex.repeat.set( 1.5, 1.5 );
    // grassTex.offset.set( 0, 0 );

    return (
        <>
            <Perf position="top-left" />

            <Sky distance={450000} sunPosition={[1, 2, 3]} inclination={0} azimuth={0.25} exposure={0.25} />

            {/*<ContactShadows*/}
            {/*    position-y={-0.99}*/}
            {/*    scale={10}*/}
            {/*    resolution={256}*/}
            {/*    far={5} // set to decide what to render vs how height the element is*/}
            {/*    // color={perf.color}*/}
            {/*    opacity={0.3}*/}
            {/*    blur={2}*/}
            {/*    // frames={1} / if this is set shadows will be baked*/}
            {/*/>*/}

            <OrbitControls makeDefault />

            <directionalLight castShadow position={ [ 3, 4, 3 ] } intensity={ 1.2 } shadow-normalBias={0.04}/>
            <directionalLight position={ [ -1, 2, -3 ] } intensity={ 0.5 } />

            <ambientLight intensity={ 0.3 } />

            <Suspense fallback={<Placeholder />}>
                <Astronaut />
            </Suspense>

            <CameraAnimation camPos={camPos} />

            <group>
                <mesh receiveShadow position-y={ - 1 } rotation-x={ - Math.PI * 0.5 } scale={ 5 }>
                    <circleGeometry args={[2, 32]} />
                    <meshStandardMaterial
                        map={grassTex}
                        // color="greenyellow"
                    />
                </mesh>
            </group>
        </>
    )
}
